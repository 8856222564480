import { createRouter, createWebHashHistory } from "@ionic/vue-router";

const routes = [
  {
    path: "/:id",
    component: () => import("@/components/Landing.vue"),
  },
  {
    path: "/jobs",
    component: () => import("@/views/Jobs.vue"),
  },
  {
    path: "/jobs/:number",
    component: () => import("@/views/JobsDetail.vue"),
  },
  {
    path: "/recs",
    component: () => import("@/views/Recs.vue"),
  },
  {
    path: "/payouts",
    component: () => import("@/views/Payouts.vue"),
  },
  {
    path: "/news",
    component: () => import("@/views/News.vue"),
  },
  {
    path: "/news/:number",
    component: () => import("@/views/NewsDetail.vue"),
  },
  {
    path: "/tmp",
    component: () => import("@/components/tmp.vue"),
  },
  {
    path: "/land",
    component: () => import("@/components/tmp2.vue"),
  },
  {
    path: "/vacancy/:number",
    component: () => import("@/Vacancy.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
