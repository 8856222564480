<template>
  <ion-app>
    <ion-page>
      <ion-tabs ref="tabs">
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom" v-if="!this.$route.params.id">
          <ion-tab-button tab="jobs" href="/jobs">
            <ion-icon aria-hidden="true" :icon="albums" />
            <ion-label>Вакансии</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="recs" href="/recs">
            <ion-icon aria-hidden="true" :icon="people" />
            <ion-label>Рекомендации</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="payouts" href="/payouts">
            <ion-icon aria-hidden="true" :icon="wallet" />
            <ion-label>Выплаты</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="news" href="/news">
            <ion-icon aria-hidden="true" :icon="newspaper" />
            <ion-label>Новости</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-page>
  </ion-app>
</template>

<script>
/* eslint-disable no-useless-escape */
/* eslint-disable */
import {
  IonApp,
  IonPage,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonButton,
} from "@ionic/vue";
import {
  albums,
  people,
  wallet,
  newspaper,
  albumsOutline,
  peopleOutline,
  walletOutline,
  newspaperOutline,
} from "ionicons/icons";
// Your web app's Firebase configuration

// messaging.usePublicVapidKey("BG25GMu-S6v-zwAwqlQ6Jy3npfROWUOTlrWudIVL1D0Zok_wG94J3hjtvtcxei_R9G-5CmKtq5ueMwebzFJQ6Rw");

export default {
  components: {
    IonApp,
    IonPage,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonButton,
  },
  data() {
    return {
      albums,
      people,
      wallet,
      newspaper,
      albumsOutline,
      peopleOutline,
      walletOutline,
      newspaperOutline,
      companyId: null,
    };
  },
  mounted() {
    if (localStorage.companyId) {
      this.companyId = localStorage.companyId;
    }
  },
};
</script>

<style>
:root {
  --ion-color-primary: #00d97f;
  --ion-color-primary-rgb: 0, 217, 127;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #00bf70;
  --ion-color-primary-tint: #1add8c;

  --ion-color-secondary: #e6e9ee;
  --ion-color-secondary-rgb: 99, 99, 99;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #575757;
  --ion-color-secondary-tint: #737373;

  --ion-color-tertiary: #8f75e6;
  --ion-color-tertiary-rgb: 143, 117, 230;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #7e67ca;
  --ion-color-tertiary-tint: #9a83e9;

  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197, 0, 15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;

  --ion-color-light: #f6f8fc;
  --ion-color-light-rgb: 246, 248, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;

  --ion-color-medium: #5f5f5f;
  --ion-color-medium-rgb: 95, 95, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #545454;
  --ion-color-medium-tint: #6f6f6f;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;
}

ion-title {
  font-size: 24px;
  font-weight: 500;
}
ion-card {
  margin-top: 10px;
  margin-bottom: 10px;
}
ion-card ion-card-header {
  padding-bottom: 5px;
}
ion-card ion-card-subtitle,
ion-card ion-card-title {
  margin-bottom: 10px;
}
ion-card ion-card-title {
  font-size: 18px;
  font-weight: 600;
}
ion-card ion-button {
  --border-radius: 8px;
  font-size: 12px;
  color: #013f36;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 20px;
  --padding-end: 20px;
}
ion-card ion-button:last-child {
  --background: #e6e9ee;
}
ion-card ion-card-subtitle {
  text-transform: none;
  font-size: 14px;
  --color: #000;
  font-weight: 400;
}
.ion-color-warning * {
  color: #fff !important;
}
ion-card.ion-color-warning ion-button:first-child {
  --background: #013f36;
}
ion-card.ion-color-warning ion-button:last-child {
  color: #013f36 !important;
}
* {
  --ion-color-primary: #00d97f;
  --ion-color-primary-shade: #4de5a6;
  --ion-color-primary-tint: #4de5a6;
  --ion-color-secondary: #00d97f;
  --ion-color-secondary-shade: #4de5a6;
  --ion-color-secondary-tint: #4de5a6;
  --ion-color-tertiary: #00d97f;
  --ion-color-tertiary-shade: #4de5a6;
  --ion-color-tertiary-tint: #4de5a6;
  --border-radius: 0px;
  --ion-color-warning: #00d97f;
}
h1,
h2 {
  font-weight: 600px !important;
}
.swiper div {
  min-width: 240px;
  margin-right: 10px;
}
.vacancy li {
  margin-bottom: 10px;
  line-height: 20px;
}
.vacancy-subtitle {
  margin-top: 10px;
  margin-bottom: 10px;
}
ion-button {
  --border-radius: 8px;
  --color: #013f36;
}
ion-toolbar {
  --opacity: 1 !important;
  --background: rgb(0, 0, 0, 0);
}
ion-button:last-child {
  --color: #000;
  --background: #ededed;
}
.news_h1 {
  margin-top: 0;
  margin-bottom: 10px;
}
.news_date {
  margin-bottom: 5px;
  font-size: 14px;
  color: #999;
}
.news_button {
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 20px;
  --padding-end: 20px;
  font-size: 14px;
  color: #013f36;
  --background: #ededed;
}
.buttons-last-slot,
.buttons-last-slot .in-toolbar {
  font-size: 14px !important;
  --padding-start: 20px;
  --padding-end: 20px;
}
.title-default {
  font-size: 18px;
}
.vacancy li,
.news_text {
  line-height: 1.35rem;
}
.success_stat {
  font-weight: 600;
  color: #00d97f;
}
.failure_stat {
  font-weight: 600;
  color: #d90000;
}

.mockup_wrapper {
  margin-top: 60px;
}

ion-toast {
  --color: #fff;
  --background: rgba(0, 0, 0, .8);
  --border-radius: 12px;
}
.toast-wrapper {
  backdrop-filter: blur(10px);
}
</style>
